import { del, get, post } from "@/helpers/api"

export const API_URL = '/marketing-bot/customer-requests'

export const API_URL_EXPORT = '/marketing-bot/export-customer-requests'

export interface ILeadItem {
    id: number,
    created_at: string,
    updated_at: string,
    customer_id: number,
    advertiser_id: number,
    region_id: number | null,
    region: null | string,
    customer: {
        id: number,
        fio: string,
        phone: string,
        tg_id: number,
        tg_username: string,
        tg_first_name: string,
        tg_last_name: string | null,
        created_at: string,
        updated_at: string
    },
    advertiser: {
        id: number,
        name: string,
        product_id: number,
        channel_id: number,
        link: string,
        published_at: string,
        created_at: string,
        updated_at: string,
        interested: number,
        product: {
            id: number,
            name: string,
            status: number,
            created_at: string,
            updated_at: string
        },
        channel: {
            id: number,
            name: string,
            status: number,
            created_at: string,
            updated_at: string,
            source_id: number
        }
    }
}

export async function apiGetLeadList(urlParams: string) {
    return await get(`${API_URL}?${urlParams}`)
}

export async function apiGetExportLeadList(urlParams: string) {
    return await get(`${API_URL_EXPORT}?${urlParams}`, {
        responseType: 'blob'
    })
}

export async function apiDeleteMultipleLeads(data: any) {
    return await post(`${API_URL}/delete-all`, data)    
}

export async function apiDeleteLead(id: number) {
    return await del(`${API_URL}/${id}`)    
}